$hexagon-background: rgba(255, 255, 255, 0.16);
$hexagon-width: 35px;

.hexagon {
  width: $hexagon-width;
  height: calc(#{$hexagon-width} * 0.55);
  background: $hexagon-background;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.hexagon:before {
  content: '';
  position: absolute;
  top: calc(-1 * #{$hexagon-width} * 0.25);
  left: 0;
  width: 0;
  height: 0;
  border-left: calc(#{$hexagon-width} * 0.5) solid transparent;
  border-right: calc(#{$hexagon-width} * 0.5) solid transparent;
  border-bottom: calc(#{$hexagon-width} * 0.25) solid $hexagon-background;
}
.hexagon:after {
  content: '';
  position: absolute;
  bottom: calc(-1 * #{$hexagon-width} * 0.25);
  left: 0;
  width: 0;
  height: 0;
  border-left: calc(#{$hexagon-width} * 0.5) solid transparent;
  border-right: calc(#{$hexagon-width} * 0.5) solid transparent;
  border-top: calc(#{$hexagon-width} * 0.25) solid $hexagon-background;
}

.icon {
  font-size: 16px;
  color: var(--primary-color);
}

.extra {
  margin-top: 8px;
  height: 17px;
  line-height: 17px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.flexRow {
  display: flex;
  flex-flow: row nowrap;
  margin: 20px 0 40px 40px;

  :global {
    .ant-space {
      margin-left: 17px;
    }
    .ant-space:not(:last-child) {
      flex-shrink: 0;
    }
  }
}

.prompt {
  margin-top: 20px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    color: #d89614;
    margin-right: 4px;
  }
}

.editableTable {
  :global {
    .editable-cell {
      position: relative;
    }

    .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }

    [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
      border: 1px solid #434343;
    }

    .editable-row .ant-form-item-explain {
      position: absolute;
      top: 100%;
      font-size: 12px;
    }
  }
}
