.headerBox {
  padding-bottom: 10px;
  font-size: 16px;
}
.insideBox {
  padding: 20px;
  background-color: #232324;
  margin-top: 15px;
  min-height: calc(100vh - 280px);
}
.currentNumBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.currentNum {
  color: red;
  padding: 0 5px 0 10px;
  font-size: 16px;
}
