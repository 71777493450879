$primary-color: #00adff;

.stepWrapper {
  // display: inline-block;
  // overflow-x: auto;
  :global {
    .ant-steps-item-process .ant-steps-item-icon {
      width: 8px;
      height: 8px;
      top: 1px;
    }

    .ant-steps-item-wait {
      & > .ant-steps-item-container {
        & > .ant-steps-item-tail::after {
          background-color: #979797;
          height: 1px;
          margin-top: 2px;
        }
        & > .ant-steps-item-content {
          & > .ant-steps-item-description {
            color: var(--text-color);
          }
        }
      }
      .ant-steps-item-icon {
        & > .ant-steps-icon {
          .ant-steps-icon-dot {
            background: #d9d9d9;
          }
        }
      }
    }
    .ant-steps-item-finish {
      & > .ant-steps-item-container {
        & > .ant-steps-item-tail::after {
          height: 1px;
          margin-top: 2px;
        }
        & > .ant-steps-item-content {
          & > .ant-steps-item-description {
            color: $primary-color;
          }
        }
      }
    }
    .ant-steps-item-process {
      & > .ant-steps-item-container {
        & > .ant-steps-item-tail::after {
          background-color: #979797;
          height: 1px;
          margin-top: 2px;
        }
        & > .ant-steps-item-content {
          & > .ant-steps-item-description {
            color: $primary-color;
          }
        }
      }
      .ant-steps-item-icon {
        & > .ant-steps-icon {
          .ant-steps-icon-dot {
            background: $primary-color;
          }
        }
      }
    }
  }
}
