.contentWrapper {
  background-color: #232324;
  padding: 10px 10px 20px 30px;
  .divider {
    color: rgba(255, 255, 255, 0.8);
    &::before {
      width: 0 !important;
    }
  }
  .content {
    padding-left: 40px;
  }
}
