.filter {
  padding-top: 18px;
  padding-bottom: 20px;
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .form {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

.linkBtn {
  color: var(--primary-color) !important;
  background: transparent !important;
}
