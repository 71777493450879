.pageHeaderWrapper {
  background-color: #232324;
  z-index: 50;
  position: sticky;
  top: 0;
  padding: 20px;
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    .formTitle {
      position: relative;
      font-weight: bold;
      font-size: 18px;
      padding-left: 10px;
      margin-bottom: 0;
      line-height: 32px;

      .titleBorder {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 4px;
        height: 20px;
        background-color: var(--primary-color);
      }
    }
  }
}
