.table {
}

.formItemFlex {
  :global {
    .mx-form.mx-form-standard.ant-form-horizontal .mx-form-item.ant-form-item .ant-form-item-label {
      width: 33px !important;
    }
  }
}
