.wrapper {
  padding: 20px;
  min-height: calc(100vh - 128px);
}
.common {
  width: 40px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
}
.success {
  background: #162312;
  border: 1px solid #274916;
  color: #49aa19;
}
.fail {
  background: #2a1215;
  border: 1px solid #58181c;
  color: #a61d24;
}
