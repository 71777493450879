.pointsBox {
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  .title {
    font-size: 16px;
    padding: 0 15px 0 0;
  }
  .divide {
    flex: 1;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      border-top: 1px solid #323233;
      box-sizing: border-box;
      height: 10px;
      top: 50%;
      width: 100%;
    }
  }
}
