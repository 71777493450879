.currentNum {
  color: red;
  padding: 0 5px;
  font-size: 16px;
}
.gridPoint {
  padding: 20px;
  background-color: #232324;
  margin-top: 15px;
}
.headerBox {
  padding-bottom: 10px;
}
.tableBox {
  min-height: calc(100vh - 354px);
}
