.blue {
  color: #4a90e2;
}

.main_fcs,
.other_fcs,
.none_fcs {
  margin-top: 8px;
}
.none_fcs {
  background: none;
}

.pcs_title > div {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.45);
  :global {
    .anticon {
      color: var(--mx-warning-color);
      margin: 0 8px;
    }
  }
}

.fcsMarginBottom {
  margin-bottom: 8px;
}
.main_line_item {
  display: flex;

  margin-bottom: 15px;
  .left {
    padding-right: 8px;
    color: var(--text-color-secondary);
  }
  .right {
    color: var(--mx-text-base-color);
  }
}
.other_line_item {
  padding: 8px 12px 0;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.05);
  :global {
    .mx-form-item.ant-form-item {
      margin-bottom: 0 !important;
      padding-bottom: 8px !important;
    }
  }
}
.click {
  cursor: pointer;
}
.disable {
  cursor: not-allowed;
  color: #ccc;
}
.white {
  color: rgba(255, 255, 255, 0.85);
  padding-right: 10px;
}
.fixW {
  width: 110px;
  padding-bottom: 5px;
}
