.drawertitleStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .drawertitleLeftStyle {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
  }
}

.drawerStyle {
  margin-top: 50px;
  height: calc(100% - 50px) !important;
  :global {
    .ant-drawer-header {
      border-top: 2px solid #1aa3df;
    }
  }
}

.top_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab_content {
    // max-height: 98px;
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    .item_sty {
      height: 64px;
      padding: 8px 0;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
      .item_wrapper_title {
        width: 55px;
        margin-right: 20px;
        align-self: flex-start;
      }
      .item_wrapper_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        .item_wrapper_box_top {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item_wrapper_deviceName {
            width: 260px;
            font-weight: 500;
            color: rgba(255,255,255,0.85);
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item_wrapper_time {
            color: rgba(255,255,255,0.65);
            line-height: 22px;
          }
        }
      }

      .item_wrapper_box_bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item_wrapper_content {
          width: 280px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .attentionLogNum_wrapper {
            display: flex;
            align-items: center;
            margin-right: 10px;
            width: 100%;
            .attentionLogNumContent {
              max-width: 80%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: rgba(255,255,255,0.65);
              line-height: 22px;
            }
            .attentionLogNumCircle {
              width: 16px;
              height: 16px;
              background: #E64242;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  :global{
    .mx-tabs .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 36px
    }
  }
}

.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
  color: #427dc3;
}

@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}
