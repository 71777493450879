
.cardBoxContentSty {
  height: 0px;
}

.desc_box {
  width: 100%;
  background: rgba(255,255,255,0.1);
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 12px;
  color: rgba(255,255,255,0.85);  
  margin-top: 8px;
  .tip_word {
    color: #FAAD14;
  }
}

.top_box {
  flex: 1;
  height: 0px;
  display: flex;
  flex-direction: column;
  .tab_content {
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    .item_sty {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: rgba(255,255,255,0.1);
      }
      .item_wrapper_title {
        width: 55px;
      }
      .item_wrapper_deviceName {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
      }
      .item_wrapper_content {
        // flex: 1;
        width: 144px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .attentionLogNum_wrapper {
          display: flex;
          align-items: center;
          margin-right: 10px;
          width: 120px;
          .attentionLogNumContent {
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .attentionLogNumCircle {
            width: 16px;
            height: 16px;
            background: #E64242;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
          }
        }
      }
      .item_wrapper_time{
        color: rgba(255,255,255,0.65);
        width: 167px;
      }
    }
  }
  :global{
    .mx-tabs .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 36px
    }
  }
}


.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
  color: #427dc3;
}

@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}